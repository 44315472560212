<template>
  <b-col
      class="my-1 ml-auto"
      md="4"
      sm="6"
  >
    <b-form-group
        :label="$i18n.t('reports.table.search.label')"
        class="mb-0 d-flex align-items-center"
        label-align-sm="right"
        label-cols-sm="3"
        label-for="filterInput"
        label-size="sm"
    >
      <b-input-group size="sm">
        <b-form-input
            id="filterInput"
            v-model="word"
            :placeholder="getPlaceholder"
            type="search"
            @input="handleInput"
            @keyup.enter="searchTable"
        />
        <b-input-group-append>
          <b-button
              :disabled="!searchable"
              variant="primary"
              @click="searchTable"
          >
            {{ $i18n.t('reports.table.search.btn') }}
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
  </b-col>
</template>

<script>
export default {
  props: {
    fieldsSearch: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
  },
  data() {
    return {
      word: "",
      lastWord: "",
      searchable: true,
    }
  },
  computed: {
    getPlaceholder() {
      return (this.placeholder !== "") ? this.placeholder : this.fieldsSearch.join(" or ")
    }
  },
  methods: {
    isEmpty() {
      return this.word === ''
    },
    isNewSearchWord() {
      return !this.isEmpty() && this.word !== this.lastWord
    },
    isEqual(val) {
      return val === this.word
    },
    getWord() {
      return this.word
    },
    searchTable() {
      this.disable()
      this.$emit('click', this.word)
    },
    handleInput() {
      if (this.word === '') {
        this.enable()
        this.updateLastWord()
        this.$emit('clear')
      }
    },
    enable() {
      this.searchable = true
    },
    disable() {
      this.searchable = false
    },
    clear() {
      this.word = ''
    },
    refresh() {
      this.word = ''
      this.lastWord = ''
    },
    updateLastWord() {
      this.lastWord = this.word
    },
    searchInCachedData(pages) {
      const filterItems = []
      pages.forEach((page) => {
        page.forEach((elem) => {
          const exists = this.fieldsSearch.some((field) => {
            return this.isEqual(elem[field])
          });
          if (exists) {
            filterItems.push(elem)
          }
        })
      })
      return filterItems
    },
  }
}
</script>
